import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PageNavigation,
  PageNavigationLink,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Radio, RadioGroup } from 'hudl-rn-uniform-ui';

const radioSnippet = require('raw-loader!../../../../../data/snippets/rn-radio.example');
const radioGroupSnippet = require('raw-loader!../../../../../data/snippets/rn-radio-group.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Single Radio</PageNavigationLink>
        <PageNavigationLink>Radio Group</PageNavigationLink>
      </PageNavigation>
      <Section title="Single Radio">
        <CodeSnippet
          scope={{ React: React, Radio: Radio }}
          code={radioSnippet}
          platform="react-native"
          gitHubLink="forms/radio"
        />

        <Section title="Props">
          <PropList>
            <PropListItem name="size" types={['oneOf', 'string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>xsmall</code>
                </li>
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>The label that appears to the right of the radio.</Text>
            </PropListItem>

            <PropListItem name="value" types={['string', 'number', 'bool']}>
              <Text>The value bound to the input.</Text>
            </PropListItem>

            <PropListItem name="checked" types={['bool']}>
              <Text>Determines whether the radio is checked.</Text>
            </PropListItem>

            <PropListItem name="disabled" types={['bool']}>
              <Text>Determines whether the radio is disabled.</Text>
            </PropListItem>

            <PropListItem name="theme" types={['oneOf', 'string']}>
              <Text>Determines the theme of the radio.</Text>
              <List type="unordered">
                <li>
                  <code>light</code> (default)
                </li>
                <li>
                  <code>dark</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="testID" types={['string']}>
              <Text>
                A unique string to control this radio in automated tests.
              </Text>
            </PropListItem>

            <PropListItem name="accessibilityLabel" types={['string']}>
              <Text>
                Overrides the text that's read by the screen reader when the user interacts with the radio.
              </Text>
            </PropListItem>

            <PropListItem name="onPress" types={['func']}>
              <Text>
                Responds to the radio being tapped. The value of
                the radio is passed.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Do not pass the <code>checked</code> attribute if you're using
              this component within a RadioGroup. Instead, use that component's{' '}
              <code>valueChecked</code> prop.
            </li>
          </List>
        </Section>
      </Section>{' '}
      <Section title="Radio Group">
        <Paragraph>
          The Radio Group is the parent element of related radios from which a
          user is expected to choose one of the options.
        </Paragraph>

        <CodeSnippet
          scope={{ React: React, Radio: Radio, RadioGroup: RadioGroup }}
          code={radioGroupSnippet}
          platform="react-native"
          gitHubLink="forms/radio-group"
        />

        <Section title="Props">
          <PropList>
            <PropListItem name="size" types={['oneOf', 'string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>xsmall</code>
                </li>
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of radios.</Text>
            </PropListItem>

            <PropListItem
              name="valueChecked"
              types={['string', 'number', 'bool']}>
              <Text>The value checked.</Text>
            </PropListItem>

            <PropListItem name="required" types={['bool']}>
              <Text>
                Determines whether the required text appears next to the header.
              </Text>
            </PropListItem>

            <PropListItem name="disabled" types={['bool']}>
              <Text>
                Determines whether the entire radio group is disabled.
              </Text>
            </PropListItem>

            <PropListItem name="space" types={['oneOf', 'string']}>
              <Text>
                Any of the{' '}
                <Link href="/resources/code-variables/space?reactnative">
                  space variables
                </Link>
                .
              </Text>
            </PropListItem>

            <PropListItem name="theme" types={['oneOf', 'string']}>
              <Text>Determines the theme of the checkbox.</Text>
              <List type="unordered">
                <li>
                  <code>light</code> (default)
                </li>
                <li>
                  <code>dark</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="testID" types={['string']}>
              <Text>
                A unique string to control this radio group in automated tests.
              </Text>
            </PropListItem>

            <PropListItem name="accessibilityLabel" types={['string']}>
              <Text>
                Overrides the text that's read by the screen reader when the user interacts with the radio group.
              </Text>
            </PropListItem>

            <PropListItem name="onChange" types={['func']}>
              <Text>
                Responds to the radio being tapped. The value of
                the clicked radio is passed.
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>{' '}
    </PlatformTab>
  );
};

export default ReactNativeTab;
